<template>
    <b-card>
      <div>

        <!-- search input -->
        <b-row class="custom-search align-items-center justify-content-end mb-1">
          <b-col cols="12" sm="8" md="6" lg="4">
            <b-form-input
                v-if="$permissionAbility(OUTREACH_SELLER_SHOW, permissions)"
                v-on:keyup="onSearch"
                v-model.lazy="searchTerm"
                placeholder="Search..."
                type="text"
                class="mb-2 mb-sm-0"
            />
          </b-col>

          <b-col cols="12" sm="auto" class="text-sm-right">
            <template v-if="$permissionAbility(OUTREACH_SELLER_CREATE, permissions)">
              <b-button
                  class="flex-shrink-0 w-100 w-sm-auto"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-on:click="showModal"
              >
                Create
              </b-button>
            </template>
          </b-col>
        </b-row>


        <!-- table -->
        <vue-good-table
            v-if="$permissionAbility(OUTREACH_SELLER_SHOW, permissions)"
            styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :rows="rows"
          :columns="columns"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [{ field: 'created_at', type: 'desc' }],
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
  
        >
          <template slot="table-row" slot-scope="props">
            <!-- Contact No -->
            <template v-if="props?.column?.field === 'format_contact'">
              <div v-if="props?.row?.contact_no">
                <span class="font-weight-bold">{{ props?.row?.contact_no }}</span>
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

            <!-- Whatsapp -->
            <template v-if="props?.column?.field === 'format_whatsapp'">
              <div v-if="props?.row?.whatsapp">
                <span class="font-weight-bold">{{ props?.row?.whatsapp }}</span>
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>
  
            <!-- Email -->
            <template v-if="props?.column?.field === 'format_email'">
              <div v-if="props?.row?.email">
                <a :href="'mailto:' + props.row.email" class="font-weight-bold">{{ props?.row?.email }}</a>
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>
  
            <!-- Website -->
            <template v-if="props?.column?.field === 'format_website'">
                <div v-if="props?.row?.website">
                    <a :href="props.row.website" target="_blank" rel="noopener noreferrer" class="font-weight-bold">
                        {{ formatWebsite(props?.row?.website) }}
                    </a>
                </div>

              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

            <!-- Skype -->
            <template v-if="props?.column?.field === 'format_skype'">
                <div v-if="props?.row?.skype">
                    <p rel="noopener noreferrer" class="font-weight-bold" :title="props.row.skype">
                        {{ formatWebsite(props?.row?.skype) }}
                    </p>
                </div>

              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <template v-if="$permissionAbility(OUTREACH_SELLER_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                />
              </span>
            </template>
              <template v-if="$permissionAbility(OUTREACH_SELLER_DELETE, permissions)">
              <span @click="onDelete(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>
            </span>
  
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
  
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
  
      <b-modal
        id="modal-outreach-seller-form"
        centered
        :title="
          modelType == 'editModel' ? 'Edit Seller' : 'Create Seller'
        "
        hide-footer
        @hidden="hiddenModal"
        no-close-on-backdrop
        size="lg"

      >
        <validation-observer ref="outeachSellerValidation">
          <b-form v-on:submit.prevent="validationForm">
            <!-- name -->
            <b-form-group
              label="Name"
              label-for="name"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                name="Seller Name"
                vid="name"
                rules="required|max:255"
              >
                <b-form-input
                  id="name"
                  type="text"
                  v-model="sellerName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
  
            <!-- Contact No -->
            <b-form-group
              label="Contact No"
              label-for="contact_no"
              
            >
              <validation-provider
                #default="{ errors }"
                name="contact no"
                vid="contact_no"
                rules="max:18"
              >
                <b-form-input
                  id="contact_no"
                  type="tel"
                  v-model="sellerContactNo"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Contact No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Whatsapp -->
            <b-form-group
              label="Whatsapp No"
              label-for="whatsapp"
              
            >
              <validation-provider
                #default="{ errors }"
                name="Whatsapp No"
                vid="whatsapp"
                rules="max:18"
              >
                <b-form-input
                  id="whatsapp"
                  type="tel"
                  v-model="sellerWhatsappNo"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Whatsapp No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Skype -->
            <b-form-group
              label="Skype"
              label-for="skype"
              
            >
              <validation-provider
                #default="{ errors }"
                name="Skype"
                vid="skype"
                rules="max:225"
              >
                <b-form-input
                  id="skype"
                  type="text"
                  v-model="sellerSkype"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Skype"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
  
            <b-row>
              <b-col md="12">
                <!-- Email -->
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    vid="email"
                    rules="max:255"

                  >
                    <b-form-input
                      id="email"
                      type="email"
                      v-model="sellerEmail"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
  
           
  
            <!-- loading button -->
            <template v-if="isSellerSubmitLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>
  
            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
  
     
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
  } from "bootstrap-vue";
  import { VueGoodTable } from "vue-good-table";
  import Ripple from "vue-ripple-directive";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, max } from "@validations";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { mapGetters } from "vuex";
  import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
  import {
    OUTREACH_SELLER_ACCESS,
    OUTREACH_SELLER_CREATE,
    OUTREACH_SELLER_EDIT,
    OUTREACH_SELLER_SHOW,
    OUTREACH_SELLER_DELETE,
  } from "@/helpers/permissionsConstant";
  
  export default {
    name: "OutreachSellerView",
    components: {
      UserAssignPolicyForm,
      BForm,
      BButton,
      BCard,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      ValidationProvider,
      ValidationObserver,
      BModal,
      BSpinner,
      BRow,
      BCol,
      VBTooltip,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        // Permissions
        OUTREACH_SELLER_ACCESS,
        OUTREACH_SELLER_CREATE,
        OUTREACH_SELLER_EDIT,
        OUTREACH_SELLER_SHOW,
        OUTREACH_SELLER_DELETE,
  
        modelType: "",
        name: "",
  
        policyCategoryId: "",
        outreachSellerId: "",
        sellerName: "",
        sellerContactNo: "",
        sellerWhatsappNo: "",
        sellerSkype: "",
        sellerEmail: "",
        sellerWebsiteLink: "",

        //table
        pageLength: 10,
        columns: [
          {
            label: "Name",
            field: "name",
            sortable: false,
          },
          {
            label: "Contact No",
            field: "format_contact",
            sortable: false,
          },
          {
            label: "Whatsapp No",
            field: "format_whatsapp",
            sortable: false,
          },
          {
            label: "Email",
            field: "format_email",
            sortable: false,
          },
          {
            label: "Skype",
            field: "format_skype",
            sortable: false,
          },
          {
            label: "Action",
            field: "action",
            sortable: false,
          },
        ],
        rows: [],
        searchTerm: "",
        delayTimer: null,
        isLoading: false,
        isSellerSubmitLoading: false,
        isUserPolicyAssignFormSubmitLoading: false,
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: [
            { field: "id", type: "desc" },
            { field: "created_at", type: "desc" },
          ],
          page: 1,
          perPage: 10,
        },
      };
    },
  
    computed: {
      ...mapGetters({
        permissions: "userModule/getPermissions",
      }),
      statusVariant() {
        const statusColor = {
          true: "light-success",
          false: "light-danger",
        };
  
        return (status) => statusColor[status];
      },
    },
  
    async created() {
      try {
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  
    methods: {

      formatWebsite(website) {
        const maxLength = 20;
        if (website.length > maxLength) {
          return website.slice(0, maxLength) + '...';
        }
        return website;
      },
      
      showModal() {
        this.$bvModal.show("modal-outreach-seller-form");
      },
  
      hiddenModal() {
        this.$bvModal.hide("modal-outreach-seller-form");
        this.resetModal();
      },
  
      resetModal() {
        this.modelType = "";
        this.outreachSellerId = "";
        this.selectStatusValue = "";
        this.policyCategoryId = "";
  
        this.sellerName = "";
        this.sellerContactNo = "";
        this.sellerWhatsappNo = "";
        this.sellerSkype = "";
        this.sellerEmail = "";
        this.sellerWebsiteLink = "";
      },
  
      onShow(value) {
        this.modelType = "editModel";
        this.outreachSellerId = value.id;
  
        this.sellerName = value.name;
        this.sellerContactNo = value.contact_no;
        this.sellerWhatsappNo = value.whatsapp;
        this.sellerSkype = value.skype;
        this.sellerEmail = value.email;
        this.sellerWebsiteLink = value.website;
  
        this.showModal();
      },
  
      formatDateTime(value) {
        if (value) {
          return this.$moment(value).format("MMM Do YYYY");
        }
      },
  
      async onDelete(row) {
        this.$swal({
          title: "Warning!",
          text: "Are You Sure You Want To Delete "+ row.name +"?",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$api.delete(`/api/outreach-sellers/${row.id}`);
  
              this.loadItems();
  
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Seller Successfully Deleted",
                },
              });
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
          }
        });
      },
  
      onSearch() {
        if (this.delayTimer) {
          clearTimeout(this.delayTimer);
          this.delayTimer = null;
        }
  
        this.delayTimer = setTimeout(() => {
          this.loadItems();
        }, 1000);
      },
  
      async getOutreachSellerItems(params) {
        return await this.$api.get("api/outreach-sellers", {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        });
      },
  
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },
  
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
  
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: params,
        });
        this.loadItems();
      },
  
      async loadItems() {
        try {

          this.isLoading = true

          const outreachSeller = await this.getOutreachSellerItems({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          });
  
          const data = outreachSeller?.data?.data;
          const meta = outreachSeller?.data?.meta;
  
          this.totalRecords = meta?.pagination?.total;
          this.rows = data;

          this.isLoading = false
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "BellIcon",
              variant: "warning",
              text: error?.response?.data?.message,
            },
          });
        }
      },
      validationForm: async function () {
        this.$refs.outeachSellerValidation.validate().then(async (success) => {
          if (success) {
            try {
              this.$refs.outeachSellerValidation.reset();
              if (this.modelType == "editModel") {
                this.isSellerSubmitLoading = true;
                await this.$api.put(
                  `/api/outreach-sellers/${this.outreachSellerId}`,
                  {
                    name: this.sellerName,
                    contact_no: this.sellerContactNo,
                    whatsapp: this.sellerWhatsappNo,
                    skype: this.sellerSkype,
                    email: this.sellerEmail,
                  }
                );
                this.isSellerSubmitLoading = false;
                this.loadItems();
  
                this.hiddenModal();
  
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Seller Successfully Updated",
                  },
                });
              } else {
                this.isSellerSubmitLoading = true;
                await this.$api.post("/api/outreach-sellers", {
                  name: this.sellerName,
                  contact_no: this.sellerContactNo,
                  whatsapp: this.sellerWhatsappNo,
                  skype: this.sellerSkype,
                  email: this.sellerEmail,
                });
                this.isSellerSubmitLoading = false;
                this.hiddenModal();
  
                this.loadItems();
  
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Seller Successfully Created",
                  },
                });
              }
            } catch (error) {
              this.isSellerSubmitLoading = false;
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }
  
              if (error?.response?.data?.errors) {
                this.$refs.outeachSellerValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        });
      },
  
     
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  
  .required-label label::after {
    content: " *";
    color: red;
  }
  </style>
  
  